import { type ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import { Text, XStack, YStack, type YStackProps, tokens } from '@fhs/ui';

export type ListItemGroupSectionProps = YStackProps & {
  heading: ReactNode;
  description?: ReactNode;
  errorMessage?: string;
};

export function ListItemGroupSection({
  children,
  heading,
  description,
  errorMessage,
  ...yStackProps
}: ListItemGroupSectionProps) {
  const hasError = Boolean(errorMessage);
  return (
    <YStack {...yStackProps} style={[styles.section, yStackProps.style]}>
      <XStack style={styles.sectionHeadingArea}>
        <YStack>
          {typeof heading === 'string' ? (
            <Text.Heading type="four" style={hasError && styles.errorText}>
              {heading}
            </Text.Heading>
          ) : (
            heading
          )}
          {typeof description === 'string' ? (
            <Text.Paragraph size="md">{description}</Text.Paragraph>
          ) : (
            description
          )}
        </YStack>
        {hasError && (
          <Text.Heading type="four" style={styles.errorText} aria-role="alert">
            {errorMessage}
          </Text.Heading>
        )}
      </XStack>

      {children}
    </YStack>
  );
}

const styles = StyleSheet.create({
  section: {
    gap: 12,
  },
  errorText: {
    color: tokens.colors.$error,
  },
  sectionHeadingArea: {
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
