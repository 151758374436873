import { FlashList } from '@shopify/flash-list';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { ActionSheet, ProductListTile, ProductListTileProps, Text, useMqSelect } from '@fhs/ui';

import { AddedToCart } from './added-to-cart';
import { Navigation } from './navigation';
import { GuideProps } from './types';
import { useGuide } from './use-guide';

const numColumns = 2;

export const Guide = (props: GuideProps) => {
  const {
    currentStepSelectedItemId,
    handleItemSelect,
    handleNavigationBack,
    navigationSteps,
    currentStep,
    handleClose,
    setCurrentStepIndex,
    ConfirmationDialog,
  } = useGuide(props);

  const { height: screenHeight } = useWindowDimensions();
  const desktopStyle = useMqSelect({ $gteDesktop: { height: screenHeight } }, {});

  return (
    <ActionSheet
      onRequestClose={handleClose}
      onClose={handleClose}
      isVisible
      containerStyle={{ height: '90%' }}
    >
      <View style={[styles.container, desktopStyle]}>
        <Navigation
          steps={navigationSteps}
          onPressStep={setCurrentStepIndex}
          onClose={handleClose}
          onPressPrev={handleNavigationBack}
        />
        <Text.Heading type="two" style={styles.title}>
          {currentStep.title}
        </Text.Heading>
        <FlashList<ProductListTileProps>
          showsVerticalScrollIndicator={false}
          data={currentStep.items}
          estimatedItemSize={200}
          numColumns={numColumns}
          renderScrollComponent={ActionSheet.ScrollView}
          contentContainerStyle={{ paddingHorizontal: 12 }}
          extraData={{ currentStepSelectedItemId }}
          renderItem={({ item, index, extraData }) => {
            const isFirstColumn = index % numColumns === 0;
            const isLastColumn = index % numColumns === numColumns - 1;
            return (
              <View
                style={[
                  styles.item,
                  isFirstColumn && styles.firstColumn,
                  isLastColumn && styles.lastColumn,
                ]}
              >
                <ProductListTile size="lg" {...item} onPress={() => handleItemSelect(item.id)} />
                {extraData.currentStepSelectedItemId === item.id && (
                  <View style={styles.addedToCartContainer}>
                    <AddedToCart />
                  </View>
                )}
              </View>
            );
          }}
        />
      </View>
      <ConfirmationDialog
        heading="You Didn't Finalize Your Combo Selections"
        body="If you leave now, your combo will not be activated. Are you sure you want to leave?"
        confirmLabel="Stay Here"
        cancelLabel="Leave"
      />
    </ActionSheet>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  title: {
    borderBottomColor: 'rgba(0, 0, 0, 0.04)',
    borderBottomWidth: 1,
    paddingVertical: 16,
    textAlign: 'center',
  },
  item: {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 12,
  },
  firstColumn: {
    paddingLeft: 0,
  },
  lastColumn: {
    paddingRight: 0,
  },
  addedToCartContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
});
