import { useCallback } from 'react';

import { Text } from '@fhs/ui';

import { Guide } from '../guide';

export const ComboGuide = ({ onClose }: { onClose: VoidFunction }) => {
  return (
    <Guide
      onRequestClose={onClose}
      steps={[
        {
          title: 'Choose your side',
          items: [
            {
              id: '1',
              title: 'Product title',
              description: 'Select at Restaurant\n420cal',
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '2',
              title: 'Product title',
              description: (
                <>
                  <Text.Ui size="xs" weight="bold">
                    + $0.70
                  </Text.Ui>
                  • 430 Cal
                </>
              ),
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '1',
              title: 'Product title',
              description: 'Select at Restaurant\n420cal',
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '2',
              title: 'Product title',
              description: (
                <>
                  <Text.Ui size="xs" weight="bold">
                    + $0.70
                  </Text.Ui>
                  • 430 Cal
                </>
              ),
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '1',
              title: 'Product title',
              description: 'Select at Restaurant\n420cal',
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '2',
              title: 'Product title',
              description: (
                <>
                  <Text.Ui size="xs" weight="bold">
                    + $0.70
                  </Text.Ui>
                  • 430 Cal
                </>
              ),
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '1',
              title: 'Product title',
              description: 'Select at Restaurant\n420cal',
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '2',
              title: 'Product title',
              description: (
                <>
                  <Text.Ui size="xs" weight="bold">
                    + $0.70
                  </Text.Ui>
                  • 430 Cal
                </>
              ),
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '1',
              title: 'Product title',
              description: 'Select at Restaurant\n420cal',
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '2',
              title: 'Product title',
              description: (
                <>
                  <Text.Ui size="xs" weight="bold">
                    + $0.70
                  </Text.Ui>
                  • 430 Cal
                </>
              ),
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
          ],
          id: 'combo-side',
        },
        {
          title: 'Choose your drink',
          items: [
            {
              id: '1',
              title: 'Product title',
              description: 'Select at Restaurant\n420cal',
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '2',
              title: 'Product title',
              description: (
                <>
                  <Text.Ui size="xs" weight="bold">
                    + $0.70
                  </Text.Ui>
                  • 430 Cal
                </>
              ),
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
          ],
          id: 'combo-drink',
        },
        {
          title: 'Choose your dessert',
          items: [
            {
              id: '1',
              title: 'Product title',
              description: 'Select at Restaurant\n420cal',
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
            {
              id: '2',
              title: 'Product title',
              description: (
                <>
                  <Text.Ui size="xs" weight="bold">
                    + $0.70
                  </Text.Ui>
                  • 430 Cal
                </>
              ),
              image: {
                asset: {
                  uri: 'https://cdn.sanity.io/images/czqk28jt/dev_fhs_us/c575732f2b08a3bd4e55c63b667e338331695352-800x545.png',
                },
                altText: '',
              },
              isAvailable: true,
              size: 'sm',
              onPress: () => null,
            },
          ],
          id: 'combo-drink',
        },
      ]}
      onItemSelect={({ goToNextStep }) => {
        setTimeout(goToNextStep, 2000);
      }}
      onGuideFinish={useCallback(() => {
        onClose();
      }, [onClose])}
    />
  );
};
