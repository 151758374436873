import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { QuantityCounter, Text, XStack } from '@fhs/ui';

import { listStyles } from './shared-styles';
import type { ListItemType } from './types';
import { optimizeSanityImageUri } from './utils';

type ListItemQuantityProps = ListItemType & {
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  value: number;
  onChangeValue: (nextValue: number) => void;
};

export function ListItemQuantity({ minHeightStyle = 'static', ...props }: ListItemQuantityProps) {
  return (
    <View
      style={[
        listStyles.item,
        minHeightStyle === 'static' && listStyles.minHeightStyleStatic,
        props.isLastItem && listStyles.lastItem,
      ]}
    >
      {props.image?.asset?.uri && (
        <Image
          contentFit="contain"
          style={listStyles.image}
          alt={props.image.altText}
          source={{ uri: optimizeSanityImageUri(props.image.asset.uri) }}
          placeholder={{ blurhash: props.image.asset.blurHash }}
        />
      )}
      <View style={listStyles.titleTextContainer}>
        <View>
          <Text.Ui weight={props.titleFontWeight ?? 'semibold'} size="md" numberOfLines={1}>
            {props.title}
          </Text.Ui>
        </View>
        {Boolean(props.subtitle) && (
          <View>
            <Text.Ui size="sm" numberOfLines={1} style={listStyles.subtitle}>
              {props.subtitle}
            </Text.Ui>
          </View>
        )}
      </View>
      <XStack style={listStyles.indicatorWithTextWrapper}>
        {Boolean(props.indicatorText) && (
          <Text.Ui size="sm" numberOfLines={1} style={listStyles.indicatorText}>
            {props.indicatorText}
          </Text.Ui>
        )}
        <QuantityCounter
          minValue={props.minValue}
          maxValue={props.maxValue}
          value={props.value}
          onChangeValue={props.onChangeValue}
          size="sm"
        >
          <QuantityCounter.Stepper style={styles.quantityCounter} />
        </QuantityCounter>
      </XStack>
    </View>
  );
}

const styles = StyleSheet.create({
  quantityCounter: {
    minWidth: 100,
    justifyContent: 'space-between',
  },
});
