import { useCallback, useRef, useState } from 'react';

import { ProductListTileProps, StepProps } from '@fhs/ui';
import useDialogModal from '@fhs-legacy/frontend/src/hooks/use-dialog-modal';

import { GuideProps } from './types';

export const useGuide = ({ steps, onItemSelect, onGuideFinish, onRequestClose }: GuideProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>(
    new Array(steps.length).fill(null)
  );
  const selectedItemsIdsRef = useRef<string[]>(selectedItemsIds);

  const itemsMap = steps[currentStepIndex].items.reduce(
    (acc, item) => ({ ...acc, [item.id]: item }),
    {} as Record<string, ProductListTileProps>
  );
  const currentStep = steps[currentStepIndex];
  const currentStepSelectedItemId = selectedItemsIds[currentStepIndex];
  const nextStepIndex = steps[currentStepIndex + 1] ? currentStepIndex + 1 : undefined;

  const [ConfirmationDialog, openConfirmationDialog] = useDialogModal({
    onConfirm: () => null,
    onCancel: () => onRequestClose(),
    onDismiss: () => onRequestClose(),
    showCancel: true,
    modalAppearanceEventMessage: '',
  });

  const handleClose = useCallback(() => {
    if (!selectedItemsIdsRef.current.every(Boolean)) {
      openConfirmationDialog();
    } else {
      onGuideFinish(selectedItemsIdsRef.current);
    }
  }, [onGuideFinish, openConfirmationDialog]);

  const handleNavigationBack = () => {
    if (currentStepIndex === 0) {
      handleClose();
    } else {
      setCurrentStepIndex(curr => curr - 1);
    }
  };

  const goToNextStep = () => {
    if (nextStepIndex) {
      setCurrentStepIndex(nextStepIndex);
    } else {
      handleClose();
    }
  };

  const handleItemSelect = (itemId: string) => {
    setSelectedItemsIds(curr => {
      const currCopy = [...curr];
      currCopy[currentStepIndex] = itemId;
      selectedItemsIdsRef.current = currCopy;
      return currCopy;
    });
    if (onItemSelect) {
      onItemSelect({ goToNextStep });
    } else {
      goToNextStep();
    }
  };

  const navigationSteps = steps.map<StepProps>((step, index) => ({
    isActive: index === currentStepIndex,
    image: itemsMap[selectedItemsIds[index]]?.image.asset.uri,
  }));

  return {
    currentStep,
    navigationSteps,
    handleItemSelect,
    handleNavigationBack,
    currentStepSelectedItemId,
    handleClose,
    setCurrentStepIndex,
    ConfirmationDialog,
  };
};
