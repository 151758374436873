import { useMutation } from '@tanstack/react-query';
import { router } from 'expo-router';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { addItemToCart } from '@fhs/cart';
import { Button, ErrorBoundary, NotFound, QuantityCounter, createMqStyles, tokens } from '@fhs/ui';

import { MenuErrorFallback } from '../components/menu-error-fallback';
import { MenuItemDetails } from '../components/menu-item-details';
import { MenuItemSkeletonLoading } from '../components/menu-item-skeleton';
import { useMenuPicker } from '../state/menu-picker';
import { formatCentsToDollars } from '../utils';

export function ScreenMenuItem({ menuPicker }: { menuPicker: ReturnType<typeof useMenuPicker> }) {
  const mqStyles = useMqStyles();

  const [isShowingNutrition, setIsShowingNutrition] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const { isPending, mutate } = useMutation({
    ...addItemToCart,
    onSuccess() {
      router.push('/v2/menu');
    },
  });

  let addItemButtonText = 'Add Item';
  // TODO: Include modifiers up-charges
  const totalPriceCents = quantity * menuPicker.priceCents;
  if (totalPriceCents) {
    addItemButtonText += ` | ${formatCentsToDollars(totalPriceCents)}`;
  }

  return (
    <View style={styles.screen}>
      <ErrorBoundary fallback={<MenuErrorFallback />}>
        <View style={styles.flex1}>
          <MenuItemDetails
            isShowingNutrition={isShowingNutrition}
            setIsShowingNutrition={setIsShowingNutrition}
          />
        </View>

        <View style={styles.layout}>
          <View style={mqStyles.footer}>
            <QuantityCounter
              size="md"
              value={quantity}
              onChangeValue={newValue => setQuantity(newValue)}
              maxValue={10}
              minValue={1}
            >
              <QuantityCounter.Stepper />
            </QuantityCounter>
            <Button
              loading={isPending}
              onPress={() => {
                // Always toggle show errors to on, it doesn't matter if there are existing error messages or not.
                menuPicker.setShowErrors(true);

                if (Object.keys(menuPicker.errorMessages).length) {
                  // There are errors, don't submit
                  return;
                }

                const itemId = menuPicker.selectedPickerAspectOption?.itemId;

                if (!itemId) {
                  throw new Error('No itemId found');
                }

                const modifiersForCart = Object.entries(menuPicker.customizationSelections).flatMap(
                  ([customizationOptionKey, selection]) => {
                    if (typeof selection === 'string') {
                      return { id: `${customizationOptionKey}:${selection}`, quantity: 1 };
                    }

                    return Object.entries(selection).flatMap(
                      ([customizationOptionOptionKey, qty]) => {
                        if (qty === 0) {
                          return [];
                        }

                        return {
                          id: `${customizationOptionKey}:${customizationOptionOptionKey}`,
                          quantity: qty,
                        };
                      }
                    );
                  }
                );

                mutate({
                  itemId,
                  quantity,
                  instructions: menuPicker.instructions,
                  modifiers: modifiersForCart,
                  isCombo: false,
                });
              }}
              style={[styles.flex1, styles.maxWidth336]}
            >
              <Button.Text>{addItemButtonText}</Button.Text>
            </Button>
          </View>
        </View>
      </ErrorBoundary>
    </View>
  );
}

export function ScreenMenuItemWithQuery() {
  const menuPicker = useMenuPicker();

  if (menuPicker.query.isLoading) {
    return (
      <View style={styles.screen}>
        <MenuItemSkeletonLoading />
      </View>
    );
  }

  // TODO: add refresh logic
  if (menuPicker.query.isError) {
    return (
      <View style={styles.screen}>
        <MenuErrorFallback />
      </View>
    );
  }

  if (!menuPicker.query.data) {
    return <NotFound buttonText="Go To Menu" buttonUrl="/menu" />;
  }

  return <ScreenMenuItem menuPicker={menuPicker} />;
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: tokens.colors.$white,
  },

  flex1: {
    flex: 1,
  },
  maxWidth336: {
    maxWidth: 336,
  },
  layout: {
    backgroundColor: tokens.colors.$white,
    padding: 12,
  },
});

const useMqStyles = createMqStyles({
  footer: {
    $base: {
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '100%',
      gap: 52,
    },
    $gteDesktop: {
      justifyContent: 'flex-end',
      maxWidth: 1440,
    },
  },
});
