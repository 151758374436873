import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

import { MqSwitch, ScrollView, createMqStyles, tokens, useMqSelect } from '@fhs/ui';

import { Background } from './background';

type NoCustomizationDetailsProps = {
  nameAndImageContent: ReactNode;
  customizationContent?: ReactNode;
};

export const ProductDetailLayout = ({
  nameAndImageContent,
  customizationContent,
}: NoCustomizationDetailsProps) => {
  const mqStyles = useMqStyles();
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);

  return (
    <ScrollView style={styles.container} keyboardShouldPersistTaps="handled">
      {(isDesktop || !customizationContent) && (
        <View style={styles.backgroundContainer}>
          <Background />
        </View>
      )}
      <View style={[mqStyles.layout]}>
        <View
          style={[
            !customizationContent && styles.minHeightFull,
            isDesktop && customizationContent ? styles.nameAndImageWidth : styles.widthFull,
          ]}
        >
          <View
            style={[
              styles.widthFull,
              styles.maxWidth548,
              styles.marginAuto,
              (!customizationContent || isDesktop) && styles.minHeightFull,
            ]}
          >
            {nameAndImageContent}
          </View>
        </View>
        {customizationContent && (
          <CustomizationContent>
            <View
              style={[
                styles.customizationContainer,
                mqStyles.customizationContainerMeasures,
                mqStyles.customizationPadding,
                isDesktop ? styles.positionAbsolute : styles.noBorder,
              ]}
            >
              {customizationContent}
            </View>
          </CustomizationContent>
        )}
      </View>
    </ScrollView>
  );
};

const CustomizationContent = ({ children }: { children: ReactNode }) => {
  const mqStyles = useMqStyles();

  return (
    <MqSwitch
      $base={() => <View style={[styles.widthFull, mqStyles.contentContainer]}>{children}</View>}
      $gteDesktop={() => (
        <ScrollView
          style={[styles.widthFull, styles.heightFull, styles.positionAbsolute]}
          contentContainerStyle={mqStyles.contentContainer}
        >
          {children}
        </ScrollView>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: '100%',
    backgroundColor: tokens.colors.$white,
  },
  backgroundContainer: {
    position: 'absolute',
    height: '100%',
    justifyContent: 'flex-end',
  },
  minHeightFull: {
    minHeight: '100%',
  },
  widthFull: {
    width: '100%',
  },
  maxWidth548: {
    maxWidth: 548,
  },
  customizationContainer: {
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    borderStyle: 'solid',
    backgroundColor: tokens.colors.$white,
    borderRadius: 20,
    top: 0,
    right: 0,
    maxWidth: 620,
    pointerEvents: 'auto',
  },
  nameAndImageWidth: {
    width: `${(4 / 7) * 100}%`,
  },
  noBorder: {
    borderWidth: 0,
  },
  positionAbsolute: {
    position: 'absolute',
  },
  marginAuto: {
    margin: 'auto',
  },
  heightFull: {
    height: '100%',
  },
});

const useMqStyles = createMqStyles({
  contentContainer: {
    $base: {
      marginHorizontal: 'auto',
      maxWidth: 548,
      width: '100%',
      paddingBottom: 8,
    },
    $gteDesktop: {
      maxWidth: 1440,
      paddingBottom: undefined,
    },
  },
  layout: {
    $base: {
      margin: 'auto',
      minHeight: '100%',
      width: '100%',
    },
    $gteDesktop: {
      flexDirection: 'row',
    },
  },
  customizationContainerMeasures: {
    $gteDesktop: {
      minHeight: '100%',
      width: `${(3 / 7) * 100}%`,
    },
  },
  customizationPadding: {
    $gteDesktop: {
      padding: 52,
    },
  },
});
