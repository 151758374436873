import { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { IconChevronRight, IconReceiptLong, Text, tokens } from '@fhs/ui';

import { OrderInstructionsActionSheet } from './order-instructions-action-sheet';

type Props = {
  orderInstructions: string | undefined;
  onInstructionsChange: (instructions: string) => void;
};

export function ExtraInstructions({ orderInstructions, onInstructionsChange }: Props) {
  const [showInstructions, setShowInstructions] = useState(false);

  const hasOrderInstructions = Boolean(orderInstructions);
  return (
    <>
      <OrderInstructionsActionSheet
        isVisible={showInstructions}
        instructions={orderInstructions}
        onClose={instructions => {
          onInstructionsChange(instructions);
          setShowInstructions(false);
        }}
      />
      <Pressable style={[styles.container]} onPress={() => setShowInstructions(true)}>
        {props => (
          <View style={[styles.section, props.pressed && styles.activeSection]}>
            <View style={[styles.iconCircle, props.pressed && styles.iconCirclePressed]}>
              <IconReceiptLong size={18} color={tokens.colors.$houseDark} />
            </View>

            <View style={styles.flex1}>
              <Text.Ui size="md" weight={hasOrderInstructions ? 'bold' : 'normal'}>
                Order Instructions
              </Text.Ui>
              {hasOrderInstructions && (
                <Text.Ui size="xs" numberOfLines={2}>
                  {orderInstructions}
                </Text.Ui>
              )}
            </View>

            <IconChevronRight size={24} />
          </View>
        )}
      </Pressable>
    </>
  );
}

const styles = StyleSheet.create({
  flex1: { flex: 1 },
  activeSection: {
    backgroundColor: tokens.colors.$houseLight,
  },
  section: {
    width: '100%',
    gap: 8,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconCirclePressed: {
    backgroundColor: tokens.colors.$black10,
  },
  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
  },
  container: {
    alignItems: 'center',
    width: '100%',
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$white,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 8,
  },
});
