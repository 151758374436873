import { useState } from 'react';

import { useMenuPicker } from '../state/menu-picker';
import type { MenuPickerData } from '../types';
import { formatCaloriesOffsetText, formatCentsToDollarsWithPlusSign } from '../utils';

import {
  ListItemCheckbox,
  ListItemGroup,
  type ListItemGroupProps,
  ListItemGroupRadioGroup,
  ListItemGroupSection,
  ListItemLinkPressable,
  ListItemQuantity,
  ListItemStepper,
  ListItemType,
} from './list-item-group-section';
import { PickerActionSheet } from './picker-action-sheet';

type CustomizationGroupDataType =
  MenuPickerData['pickerAspect']['options'][number]['item']['customizations']['displayGroups'][number];

type CustomizationGroupDisplayType = 'RADIO' | 'QUANTITY' | 'MIXED';

function getCustomizationGroupDisplayType(
  customizationGroup: CustomizationGroupDataType
): CustomizationGroupDisplayType {
  if (customizationGroup.options.length === 1) {
    const optionDisplayType = customizationGroup.options[0].displayType;
    if (optionDisplayType === 'RADIO' || optionDisplayType === 'QUANTITY') {
      return optionDisplayType;
    }
  }

  return 'MIXED';
}

export type CustomizationGroupProps = Omit<ListItemGroupProps, 'items'> & {
  customizationGroup: CustomizationGroupDataType;
};

export function CustomizationGroup({
  customizationGroup,
  ...listItemGroupProps
}: CustomizationGroupProps) {
  const menuPicker = useMenuPicker();
  const [pickerActionSheetVisibility, setPickerActionSheetVisibility] = useState<
    Record<string, boolean>
  >({});
  const displayType = getCustomizationGroupDisplayType(customizationGroup);

  const listGroupItems: ListItemGroupProps['items'] = customizationGroup.options.map(
    customizationOption => {
      return {
        id: customizationOption.key,
        render: (listItemProps: ListItemType) => {
          switch (customizationOption.displayType) {
            case 'CHECKBOX': {
              const onOption = customizationOption.options.find(o => o.multiplier > 0);
              const offOption = customizationOption.options.find(o => o.multiplier === 0);
              const checked =
                (menuPicker.customizationSelections[customizationOption.key] ??
                  menuPicker.defaultCustomizationSelections[customizationOption.key]) ===
                onOption?.key;

              return (
                <ListItemCheckbox
                  {...listItemProps}
                  checked={checked}
                  onChange={nextState =>
                    menuPicker.setCustomizationSelections(prev => ({
                      ...prev,
                      [customizationOption.key]: nextState ? onOption?.key : offOption?.key,
                    }))
                  }
                  image={onOption?.image}
                  title={customizationOption.displayName}
                  subtitle={formatCaloriesOffsetText(onOption?.caloriesOffset)}
                  indicatorText={formatCentsToDollarsWithPlusSign(onOption?.upChargeCents)}
                />
              );
            }

            case 'SELECT': {
              const currentValue =
                menuPicker.customizationSelections[customizationOption.key] ??
                menuPicker.defaultCustomizationSelections[customizationOption.key];
              const selectedOption = customizationOption.options.find(o => o.key === currentValue);

              return (
                <>
                  <ListItemLinkPressable
                    {...listItemProps}
                    onPress={() =>
                      setPickerActionSheetVisibility(visibility => ({
                        ...visibility,
                        [customizationOption.key]: true,
                      }))
                    }
                    image={selectedOption?.image}
                    title={customizationOption.displayName}
                    subtitle={[
                      formatCentsToDollarsWithPlusSign(selectedOption.upChargeCents),
                      selectedOption.displayName,
                    ]
                      .filter(Boolean)
                      .join(' • ')}
                  />
                  <PickerActionSheet
                    isVisible={Boolean(pickerActionSheetVisibility[customizationOption.key])}
                    onClose={() =>
                      setPickerActionSheetVisibility(visibility => ({
                        ...visibility,
                        [customizationOption.key]: false,
                      }))
                    }
                    selectedOptionKey={selectedOption.key}
                    onSelect={optionSelected => {
                      menuPicker.setCustomizationSelections(selections => ({
                        ...selections,
                        [customizationOption.key]: optionSelected.key,
                      }));
                    }}
                    options={customizationOption.options}
                  />
                </>
              );
            }

            case 'STEPPER': {
              const currentValue = (menuPicker.customizationSelections[customizationOption.key] ??
                menuPicker.defaultCustomizationSelections[customizationOption.key]) as string; // forcing to string because stepper will never have quantities

              const selectedOption = customizationOption.options.find(o => o.key === currentValue);

              return (
                <ListItemStepper
                  {...listItemProps}
                  options={customizationOption.options.map(o => ({
                    label: o.prefix ?? o.displayName,
                    value: o.key,
                  }))}
                  value={currentValue}
                  onChangeValue={nextValue =>
                    menuPicker.setCustomizationSelections(selections => ({
                      ...selections,
                      [customizationOption.key]: nextValue,
                    }))
                  }
                  image={selectedOption?.image}
                  title={customizationOption.displayName}
                  subtitle={formatCaloriesOffsetText(selectedOption?.caloriesOffset)}
                />
              );
            }

            default: {
              return null;
            }
          }
        },
      };
    }
  );

  switch (displayType) {
    case 'MIXED': {
      return (
        <ListItemGroupSection heading={customizationGroup.displayGroup.displayName}>
          <ListItemGroup {...listItemGroupProps} items={listGroupItems} />
        </ListItemGroupSection>
      );
    }

    case 'QUANTITY': {
      const customizationOption = customizationGroup.options[0];

      const quantityState = (menuPicker.customizationSelections[customizationOption.key] ??
        menuPicker.defaultCustomizationSelections[customizationOption.key]) as Record<
        string,
        number
      >; // forcing to record because quantity will always have quantities

      const sectionDescription =
        customizationOption.minAmount &&
        customizationOption.maxAmount &&
        customizationOption.minAmount !== customizationOption.maxAmount
          ? `Choose ${customizationOption.minAmount} to ${customizationOption.maxAmount} options`
          : `Choose ${customizationOption.minAmount} options`;

      const errorMessage =
        menuPicker.showErrors && menuPicker.errorMessages[customizationOption.key];

      return (
        <ListItemGroupSection
          heading={customizationGroup.displayGroup.displayName}
          description={sectionDescription}
          errorMessage={errorMessage}
        >
          <ListItemGroup
            {...listItemGroupProps}
            errorMessage={errorMessage}
            items={customizationOption.options.map(opt => ({
              id: opt.id,
              render: (listItemProps: ListItemType) => (
                <ListItemQuantity
                  {...listItemProps}
                  title={opt.displayName}
                  subtitle={opt.caloriesOffset && `${opt.caloriesOffset} Cal`}
                  indicatorText={formatCentsToDollarsWithPlusSign(opt.upChargeCents)}
                  image={opt.image}
                  minValue={0}
                  maxValue={customizationOption.maxAmount}
                  value={quantityState[opt.key]}
                  onChangeValue={nextAmount =>
                    menuPicker.setCustomizationSelections(selections => ({
                      ...selections,
                      [customizationOption.key]: {
                        ...quantityState,
                        [opt.key]: nextAmount,
                      },
                    }))
                  }
                />
              ),
            }))}
          />
        </ListItemGroupSection>
      );
    }

    case 'RADIO': {
      const customizationOption = customizationGroup.options[0];

      const value = (menuPicker.customizationSelections[customizationOption.key] ??
        menuPicker.defaultCustomizationSelections[customizationOption.key]) as string; // forcing to string because radio will never have quantities

      return (
        <ListItemGroupSection heading={customizationGroup.displayGroup.displayName}>
          <ListItemGroupRadioGroup
            {...listItemGroupProps}
            value={value}
            onChange={nextValue =>
              menuPicker.setCustomizationSelections(selections => ({
                ...selections,
                [customizationOption.key]: nextValue,
              }))
            }
            options={customizationOption.options.map(opt => ({
              value: opt.key,
              title: opt.displayName,
              subtitle: formatCaloriesOffsetText(opt.caloriesOffset),
              indicatorText: formatCentsToDollarsWithPlusSign(opt.upChargeCents),
              image: opt.image,
            }))}
          />
        </ListItemGroupSection>
      );
    }
  }
}
