import { RadioGroup } from '@fhs/ui';

import {
  ListItemGroup,
  type ListItemGroupProps,
  ListItemRadio,
  type ListItemRadioProps,
} from '../list-item-group-section';

type ListItemGroupRadioGroupProps = Omit<ListItemGroupProps, 'items'> & {
  value: string;
  options: Array<ListItemRadioProps>;
  onChange: (nextPickerAspectOptionId: string) => void;
};

export function ListItemGroupRadioGroup({
  value,
  onChange,
  options = [],
  ...listItemGroupProps
}: ListItemGroupRadioGroupProps) {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <ListItemGroup
        {...listItemGroupProps}
        items={options.map(opt => {
          return {
            id: opt.value,
            render: listItemProps => (
              <ListItemRadio
                value={opt.value}
                title={opt.title}
                image={opt.image}
                subtitle={opt.subtitle}
                indicatorText={opt.indicatorText}
                indicatorSize={opt.indicatorSize}
                disabled={opt.disabled}
                {...listItemProps}
              />
            ),
          };
        })}
      />
    </RadioGroup>
  );
}
