import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { IconChevronLeft, IconClose, ItemStepper, StepProps } from '@fhs/ui';

interface NavigationProps {
  steps: StepProps[];
  onPressPrev: () => void;
  onPressStep: (index: number) => void;
  onClose: () => void;
}

export function Navigation({ onPressPrev, onPressStep, onClose, steps }: NavigationProps) {
  return (
    <View style={styles.navigation}>
      <TouchableOpacity style={{ justifyContent: 'center' }} onPress={onPressPrev}>
        <IconChevronLeft size={24} />
      </TouchableOpacity>
      <View style={styles.itemStepperContainer}>
        <ItemStepper steps={steps} onStepClick={onPressStep} />
      </View>
      <TouchableOpacity style={{ justifyContent: 'center' }} onPress={onClose}>
        <IconClose size={24} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  itemStepperContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    flexShrink: 1,
  },
  navigation: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 12,
    alignItems: 'center',
  },
});
